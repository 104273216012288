import { Request } from '@/http/request'

// 通信接口增添
export function amountAdd(parameter: any){
  return Request.axiosInstance.post('/open/amount/add', parameter)
}
// 通信接口删除
export function amountDelete(parameter: any){
    return Request.axiosInstance.post('/open/amount/delete', parameter)
}
// 通信接口修改
export function amountEdit(parameter: any){
    return Request.axiosInstance.post('/open/amount/edit', parameter)
}
// 通信接口修改
export function amountGet(parameter: any){
    return Request.axiosInstance.post('/open/amount/get', parameter)
}
// 通信接口修改
export function amountList(parameter: any){
    return Request.axiosInstance.post('/open/amount/list', parameter)
}

export function amountQueryName(parameter: any){
    return Request.axiosInstance.post('/open/amount/query-name', parameter)
}
export function industryid(parameter: any) {
  return Request.axiosInstance.post('/open/amount/get-industry-by-amount-id', parameter)
}
export function industryidlist(parameter: any) {
  return Request.axiosInstance.post('/open/industry/query', parameter)
}

export function scriptMatchingView(parameter: any) {
  return Request.axiosInstance.post('/open/voice-api-script/list', parameter)
}
export function scriptAddition(parameter: any) {
  return Request.axiosInstance.post('/open/voice-api-script/add', parameter)
}
export function scriptEditor(parameter: any) {
  return Request.axiosInstance.post('/open/voice-api-script/edit', parameter)
}
export function scriptDeletion(parameter: any) {
  return Request.axiosInstance.post('/open/voice-api-script/delete', parameter)
}
export function scriptQuery(parameter: any) {
  return Request.axiosInstance.post('/open/voice-api-script/get-by-key', parameter)
}
//湖南回拨做此项管理列表
export function amountListHunan(parameter: any){
  return Request.axiosInstance.post('/open/qty-seat/list', parameter)
}
//湖南批量删除
export function amountDeleteHunan(parameter: any){
  return Request.axiosInstance.post('/open/qty-seat/delete-seat', parameter)
}
//齐鲁回拨做此项管理列表
export function amountListQilu(parameter: any){
  return Request.axiosInstance.post('/open/ql-seat/list', parameter)
}
//齐鲁批量删除
export function amountDeleteQilu(parameter: any){
  return Request.axiosInstance.post('/open/ql-seat/delete-seat', parameter)
}
//虚拟商外显列表
export function ExplicitBinding(parameter: any){
  return Request.axiosInstance.post('/open/hw-axb/list', parameter)
}
//虚拟商外显批量删除
export function ExplicitBindingdelete(parameter: any){
  return Request.axiosInstance.post('/open/hw-axb/batch-delete', parameter)
}
//虚拟商外显增加
export function ExplicitBindingAdd(parameter: any){
  return Request.axiosInstance.post('/open/hw-axb/add', parameter)
}
//虚拟商外显批量增加
export function ExplicitBindingAddAll(parameter: any){
  return Request.axiosInstance.post('/open/hw-axb/batch-add', parameter)
}
//虚拟商外显批量增加
export function setOption(parameter: any){
  return Request.axiosInstance.post('/open/amount-option/set-option', parameter)
}
export default {ExplicitBindingAddAll,ExplicitBindingAdd,ExplicitBindingdelete,ExplicitBinding,amountDeleteHunan,amountListHunan, amountAdd,amountDelete,amountEdit,amountGet,amountList, amountQueryName,industryid,industryidlist,scriptMatchingView,scriptAddition,scriptEditor
  ,scriptDeletion,scriptQuery,amountListQilu,amountDeleteQilu,setOption}