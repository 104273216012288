import {Request} from '@/http/request'

export function queryName(parameter: any) {
    return Request.axiosInstance.post('/system/dictionary/query-name', parameter)
}

export function query(parameter: any) {
    return Request.axiosInstance.post('/system/enums/query', parameter)
}

export function industry(parameter: any) {
    return Request.axiosInstance.post('/open/industry/list', parameter)
}

//所选行业
export function industryid(parameter: any) {
    return Request.axiosInstance.post('/open/amount/get-industry-by-amount-id', parameter)
}

export function cityList(parameter: any) {
    return Request.axiosInstance.post('/open/area/drop-down-tree-list', parameter)
}

export default {queryName, query, industry, industryid, cityList}